"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
const JADEDocumentationViewer_1 = require("./JADEDocumentationViewer");
function init(options) {
    try {
        return new JADEDocumentationViewer_1.JADEDocumentationViewer(options);
    }
    catch (error) {
        console.error(error);
    }
}
exports.init = init;
